<template>
  <v-container>
    <v-row>
      <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>
      <v-col md="8" sm="12">
        <news-items></news-items>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewsItems from "@/components/News/NewsItems";

export default {
  name: "Home",
  components: {
    NewsItems,
  },

  computed: {},
};
</script>
