<template>
  <v-container>
    <v-toolbar>
      <v-toolbar-title>
        News
      </v-toolbar-title>
    </v-toolbar>

    <v-card
      v-for="{ id, news, date } in latestNews"
      :key="id"
      outlined
      class="my-2"
    >
      <v-card-text v-html="news" class="text-left"></v-card-text>
      <v-card-subtitle v-html="date" class="text-right"></v-card-subtitle>
    </v-card>
  </v-container>
</template>

<script>
import marked from "marked";
import uniqid from "uniqid";

const renderer = new marked.Renderer();

renderer.link = function(href, title, text) {
  return (
    '<a target="_blank" href="' +
    href +
    '" title="' +
    title +
    '">' +
    text +
    "</a>"
  );
};

export default {
  computed: {
    latestNews() {
      const news = this.$store.state.latestNews;
      return news.length == 0
        ? []
        : news.map((entry) => {
            entry.news = marked(entry.news, { renderer: renderer }).replace(
              /<\/?p>/,
              ""
            );
            entry.id = uniqid();
            return entry;
          });
    },
  },
};
</script>
